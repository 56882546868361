<template>
  <div class="group-row">

      <tr class="flex flex-wrap md:flex-no-wrap md:wrapped">
        <td class="flex name-cell w-[25%] items-center px-4">
        <button
          class="text-gray-600 font-semibold flex-grow-0 hover:text-primary focus:outline-none focus:text-primary transition-colors duration-200 ease"
          @click="goToGroupMembers"
        >
          {{ this.group.name }}
        </button>
        </td>
        <td
          class="bg-gray-200 md:bg-transparent w-1/3 md:w-[15%] md:flex items-center"
        >
          {{ formattedCreateDate }}
        </td>
        <td
          class="bg-gray-200 md:bg-transparent w-1/3 md:w-[15%] md:flex items-center"
        >
          {{ this.group.numberOfMembers }}
        </td>
        <td
          class="bg-gray-200 md:bg-transparent md:w-[10%] md:flex items-center"
        >
          {{ formattedActive }}
        </td>
        <td
          class="bg-gray-200 md:bg-transparent w-1/3 md:w-[15%] md:flex items-center"
        >
          {{ this.adminList }}
        </td>
        <td class="w-[20%] md:flex items-center">
          <button
            class="text-gray-600 font-semibold flex-grow-0 hover:text-primary focus:outline-none focus:text-primary transition-colors duration-200 ease"
            @click="goToGroup"
          >
            View Members
          </button>
          <button
            class="text-gray-600 font-semibold flex-grow-0 ml-4 hover:text-primary focus:outline-none focus:text-primary transition-colors duration-200 ease"
            @click="showDeactivateDialog = true"
          >
            {{ activateActionString }}
          </button>
        </td>
      </tr>
    <transition name="component-fade" mode="out-in">
      <DialogDefault
        v-if="showDeactivateDialog"
        :header="`${activateActionString} Group`"
        :text="`Are you sure you want to ${activateActionString.toLowerCase()} group ${this.group.name}?`"
        :applyBtnText="activateActionString"
        @cancel="showDeactivateDialog = false"
        @apply="handleDeactivate()"
      />
    </transition>
  </div>
</template>


<script>
import DialogDefault from '@/components/DialogDefault.vue';
import { mapActions } from 'vuex';
import { getISOEightySixOOneToDateFormatted } from '../filters';

export default {
  name: 'SuperAdminUserTableRow',
  components: {
    DialogDefault,
  },
  props: {
    /**
     * Group user to display in row
     */
    group: {
      type: Object,
    },
  },
  computed: {
    formattedCreateDate() {
      return getISOEightySixOOneToDateFormatted(this.group.createdAt);
    },
    formattedLastLoginDate() {
      return getISOEightySixOOneToDateFormatted(this.user.lastLogin);
    },
    adminList() {
      return this.group.admins.map(((admin) => {
        return `${admin.firstName} ${admin.lastName}`;
      })).join(', ');
    },
    activateActionString() {
      return this.group.isDeactivated ? 'Reactivate' : 'Deactivate';
    },
    formattedActive() {
      return this.group.isDeactivated ? 'No' : 'Yes';
    },
  },
  methods: {
    ...mapActions([
      'fetchGroupUsers',
      'updateGroup',
      'fetchCurrentUser',
    ]),
    goToGroup() {
      const params = {
        slug: this.group.slug,
        name: this.group.name,
      };
      this.$emit('members-selected', params);
    },
    goToGroupMembers() {
      const routeData = this.$router.resolve({ name: 'members', params: { group: this.group.slug } });
      window.open(routeData.href, '_blank');
    },
    handleDeactivate() {
      this.updateGroup({
        groupSlug: this.group.slug,
        group: { is_deactivated: !this.group.isDeactivated },
      }).then((response) => {
        if (response.success) {
          this.$emit('refetch');
        } else if (response.errors) {
          console.error(response.errors);
        }
        this.fetchCurrentUser();
        this.showDeactivateDialog = false;
      });
    },
  },
  data() {
    return {
      showDeactivateDialog: false,
    };
  },
};
</script>

<style scoped>
.group-row {
  border-top: 1px solid #807C77;
}
</style>
