<template>
  <div class="bg-gray-200 md:w-full mt-4 mx-4 sm:mx-0 rounded mb-6 md:px-4">
    <ul class="flex pl-0 pr-2 md:pr-8 md:pl-8 max-w-screen-xl mx-auto flex-col md:flex-row">
      <li class="py-2 md:hidden text-lg font-semibold cursor-pointer flex
           items-center cursor-pointer"
          :class="{ 'border-b-2 border-b-gray-600': expandedMobileView }"
          @click="toggleExpandedMobileRow">
        <span class="text-primary pl-4 md:pl-0
           border-l-2 border-primary md:border-l-transparent">{{ formattedCurrentView }}</span>
        <div
          class="block md:hidden bg-gray-300 w-12 h-12 mr-2 md:mr-4
            flex rounded animation-focus-default ml-auto items-center justify-center">
          <span
            class="inline-block transition-transform duration-200 ease transform text-gray-600"
            :class="[{
              'rotate-180': expandedMobileView,
            }]">
            <Chevron class="w-4 h-4"></Chevron>
          </span>
        </div>
      </li>
      <li
        :class="{
          'text-gray-700 md:text-primary md:border-primary': currentPageView === 'users',
          'text-gray-700 border-transparent': currentPageView !== 'users',
          'hidden md:block': !expandedMobileView,
        }"
        class="pt-4 pb-4 md:pt-6 md:pb-6 text-lg font-semibold
        cursor-pointer mr-6 md:border-b-2 pl-4 md:pl-0"
        @click="handleNavLinkClick('users')">Users</li>
      <li
        :class="{
          'text-gray-700 md:text-primary md:border-primary': currentPageView === 'groups',
          'text-gray-700 border-transparent': currentPageView !== 'groups',
          'hidden md:block': !expandedMobileView,
        }"
        class="pb-6 md:pt-6 md:pb-6 text-lg font-semibold
        cursor-pointer mr-6 md:border-b-2 pl-4 md:pl-0"
        @click="handleNavLinkClick('groups')">Groups</li>
    </ul>
  </div>
</template>


<script>
import Chevron from '@/assets/chevron.svg';

export default {
  name: 'SuperAdminSubNavigation',
  components: {
    Chevron,
  },
  props: {
    /**
     * string for which nav options is currently selected
     */
    currentPageView: {
      type: String,
    },
    /**
     * action to update currentPageView on nav link click
     */
    updateCurrentPageView: {
      type: Function,
    },
  },
  data() {
    return {
      expandedMobileView: false,
    };
  },
  computed: {
    formattedCurrentView() {
      return this.currentPageView === 'groups' ? 'Groups' : 'Users';
    },
  },
  methods: {
    toggleExpandedMobileRow() {
      this.expandedMobileView = !this.expandedMobileView;
    },
    handleNavLinkClick(view) {
      this.expandedMobileView = false;
      this.updateCurrentPageView(view);
    },
  },
};
</script>

<style scoped>

</style>
