<template>
  <div class="user-row">
    <tr class="flex flex-wrap md:flex-no-wrap md:wrapped">
      <td class="lex name-cell w-1/5 items-center px-4" @click="goToProfile">
        <span
          class="text-gray-600 font-semibold flex-grow-0 hover:text-primary cursor-pointer focus:outline-none focus:text-primary transition-colors duration-200 ease"
        >
          {{ this.user.lastName }}, {{ this.user.firstName }}
        </span>
      </td>
      <td class="w-1/4 bg-gray-200 md:bg-transparent md:flex items-center">
        <template v-if="editViewVisible">
          <input
            class="w-11/12"
            type="text"
            v-model="currentUserEmail"
            @keydown.enter="saveEdit"
            @keydown.escape="hideEditView"
          />
        </template>
        <template v-else>
          <span class="email-cell">
            {{ this.user.email }}
          </span>
        </template>
      </td>
      <td class="bg-gray-200 md:bg-transparent w-1/12 md:flex items-center">
        {{ formattedJoinedDate }}
      </td>
      <td class="bg-gray-200 md:bg-transparent w-1/12 md:flex items-center">
        {{ formattedLastLoginDate }}
      </td>
      <td class="bg-gray-200 md:bg-transparent w-1/12 md:flex items-center">
        <template v-if="editViewVisible">
          <select v-model="currentUserActive" class="w-11/12" @keydown.enter="saveEdit">
            <option>Yes</option>
            <option>No</option>
          </select>
        </template>
        <template v-else>
          {{ formattedActive }}
        </template>
      </td>
      <td
        v-if="!userHasGroupAdminFlag"
        class="bg-gray-200 md:bg-transparent w-2/12 md:flex items-center"
      >
        <span v-html="formattedUserGroup"> </span>
      </td>
      <td
        class="bg-gray-200 md:bg-transparent w-2/12 md:flex items-center"
        v-if="userHasGroupAdminFlag"
      >
        <template v-if="editViewVisible">
          <select v-model="currentUserGroupAdmin" class="w-11/12" @keydown.enter="saveEdit">
            <option>Yes</option>
            <option>No</option>
          </select>
        </template>
        <template v-else>
          {{ formattedAdmin }}
        </template>
      </td>
      <td v-if="editViewVisible" class="w-1/8 md:flex items-center">
        <button
          class="text-gray-600 font-semibold flex-grow-0 hover:text-primary focus:outline-none focus:text-primary transition-colors duration-200 ease"
          @click="saveEdit"
        >
          Save
        </button>
        <button
          class="text-gray-600 font-semibold ml-4 flex-grow-0 hover:text-primary focus:outline-none focus:text-primary transition-colors duration-200 ease"
          @click="hideEditView"
        >
          Cancel
        </button>
      </td>
      <td v-else class="w-1/8 md:flex items-center">
        <button
          class="text-gray-600 font-semibold flex-grow-0 hover:text-primary focus:outline-none focus:text-primary transition-colors duration-200 ease"
          @click="showEditView"
        >
          Edit
        </button>
        <button
          class="text-gray-600 font-semibold ml-4 flex-grow-0 hover:text-primary focus:outline-none focus:text-primary transition-colors duration-200 ease"
          @click="showDeleteView"
        >
          Delete
        </button>
      </td>
    </tr>

    <transition name="component-fade" mode="out-in">
      <DialogDefault
        v-if="deleteViewVisible"
        header="Delete user"
        @cancel="hideDeleteView"
        @apply="confirmDeleteUser"
        is-show-close-btn
        scheme="secondary"
        apply-btn-text="Yes, Delete"
        container-class="sm:w-[440px]"
        action-btn-class="w-full md:w-[220px] text-sm"
        action-wrapper-class="justify-between"
      >
        <p class="font-normal mb-4">
          Are you sure you want to delete
          <span class="font-semibold mb-2">
            {{ this.user.lastName }}, {{ this.user.firstName }} </span
          >?
          <br />
          <span class="font-semibold">This action is destructive and cannot be undone</span>
        </p>
      </DialogDefault>

      <DialogDefault
        class="left-0"
        v-if="isShowSuccessModal"
        header="Success"
        @cancel="hideSuccessModal"
        :is-show-close-btn="false"
        :is-show-control-buttons="false"
        scheme="secondary"
        container-class="sm:w-[440px]"
      >
        <div>
          <p class="mt-4 mb-8 text-base font-normal leading-[160%]">
            You have successfully deleted user
            <span class="font-semibold mb-2">
              {{ this.user.lastName }}, {{ this.user.firstName }}
            </span>
          </p>
          <div class="flex justify-center">
            <Button class="max-w-[220px] mt-3" type="primary" @onClick="hideSuccessModal">
              OK
            </Button>
          </div>
        </div>
      </DialogDefault>
    </transition>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import DialogDefault from '@/components/DialogDefault.vue';
import Button from '@/components/Button.vue';
import { getISOEightySixOOneToDateFormatted } from '../filters';

export default {
  name: 'SuperAdminUserTableRow',
  components: {
    Button,
    DialogDefault,
  },
  props: {
    /**
     * Group user to display in row
     */
    user: {
      type: Object,
    },
    groupSlug: {},
  },
  computed: {
    formattedJoinedDate() {
      return getISOEightySixOOneToDateFormatted(this.user.dateJoined);
    },
    formattedLastLoginDate() {
      return getISOEightySixOOneToDateFormatted(this.user.lastLogin);
    },
    formattedActive() {
      return this.user.isActive ? 'Yes' : 'No';
    },
    formattedUserGroup() {
      return this.user.profile.groups
        .map((x) => {
          return `<a target="blank" href="/group/${x.slug}">${x.name}</a>`;
        })
        .join(', ');
    },
    formattedAdmin() {
      return this.user.isAdmin ? 'Yes' : 'No';
    },
    userHasGroupAdminFlag() {
      return this.user.isAdmin !== undefined;
    },
  },
  methods: {
    ...mapActions(['saveUser', 'deleteUser', 'setGroupAdmin', 'fetchCurrentUsers']),
    showEditView() {
      this.currentUserEmail = this.user.email;
      this.editViewVisible = true;
    },
    hideEditView() {
      this.editViewVisible = false;
    },
    hideSuccessModal() {
      this.isShowSuccessModal = false;
      this.$emit('refetch');
    },
    showSuccessModal() {
      this.isShowSuccessModal = true;
    },
    showDeleteView() {
      this.deleteViewVisible = true;
    },
    hideDeleteView() {
      this.deleteViewVisible = false;
    },
    saveEdit() {
      this.$parent.showToast = false;
      this.user.email = this.currentUserEmail;
      this.user.isActive = this.currentUserActive === 'Yes';
      this.saveUser(this.user)
        .then((response) => {
          if (response.success) {
            this.$parent.showToast = true;
            this.editViewVisible = false;
          }
        })
        .then(() => {
          if (this.groupSlug) {
            this.user.isAdmin = this.currentUserGroupAdmin === 'Yes';
            const params = {
              groupSlug: this.groupSlug,
              isAdmin: this.currentUserGroupAdmin === 'Yes',
              userId: this.user.id,
            };
            this.setGroupAdmin(params);
          }
        });
    },
    async confirmDeleteUser() {
      try {
        this.hideDeleteView();
        await this.deleteUser(this.user.id);
        this.showSuccessModal();
      } catch (e) {
        console.log(e);
      }
    },
    goToProfile() {
      const params = {
        id: this.user.id,
      };
      this.$emit('user-selected', params);
    },
  },
  watch: {
    user: {
      handler() {
        if (this.user.isAdmin) {
          this.currentUserGroupAdmin = 'Yes';
        } else {
          this.currentUserGroupAdmin = 'No';
        }
      },
      deep: true,
    },
  },
  data() {
    return {
      isShowSuccessModal: false,
      deleteViewVisible: false,
      editViewVisible: false,
      currentUserEmail: {},
      currentUserActive: this.user.isActive ? 'Yes' : 'No',
      currentUserGroupAdmin: this.user.isAdmin ? 'Yes' : 'No',
    };
  },
};
</script>

<style scoped>
.user-row {
  border-top: 1px solid #807c77;
}
#email {
  padding: 3px;
}
.email-cell {
  max-width: 300px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
</style>
