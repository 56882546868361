var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bg-gray-200 md:w-full mt-4 mx-4 sm:mx-0 rounded mb-6 md:px-4"},[_c('ul',{staticClass:"flex pl-0 pr-2 md:pr-8 md:pl-8 max-w-screen-xl mx-auto flex-col md:flex-row"},[_c('li',{staticClass:"py-2 md:hidden text-lg font-semibold cursor-pointer flex items-center cursor-pointer",class:{ 'border-b-2 border-b-gray-600': _vm.expandedMobileView },on:{"click":_vm.toggleExpandedMobileRow}},[_c('span',{staticClass:"text-primary pl-4 md:pl-0 border-l-2 border-primary md:border-l-transparent"},[_vm._v(_vm._s(_vm.formattedCurrentView))]),_c('div',{staticClass:"block md:hidden bg-gray-300 w-12 h-12 mr-2 md:mr-4 flex rounded animation-focus-default ml-auto items-center justify-center"},[_c('span',{staticClass:"inline-block transition-transform duration-200 ease transform text-gray-600",class:[{
            'rotate-180': _vm.expandedMobileView,
          }]},[_c('Chevron',{staticClass:"w-4 h-4"})],1)])]),_c('li',{staticClass:"pt-4 pb-4 md:pt-6 md:pb-6 text-lg font-semibold cursor-pointer mr-6 md:border-b-2 pl-4 md:pl-0",class:{
        'text-gray-700 md:text-primary md:border-primary': _vm.currentPageView === 'users',
        'text-gray-700 border-transparent': _vm.currentPageView !== 'users',
        'hidden md:block': !_vm.expandedMobileView,
      },on:{"click":function($event){return _vm.handleNavLinkClick('users')}}},[_vm._v("Users")]),_c('li',{staticClass:"pb-6 md:pt-6 md:pb-6 text-lg font-semibold cursor-pointer mr-6 md:border-b-2 pl-4 md:pl-0",class:{
        'text-gray-700 md:text-primary md:border-primary': _vm.currentPageView === 'groups',
        'text-gray-700 border-transparent': _vm.currentPageView !== 'groups',
        'hidden md:block': !_vm.expandedMobileView,
      },on:{"click":function($event){return _vm.handleNavLinkClick('groups')}}},[_vm._v("Groups")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }