<template>
  <section
    ref="modal"
    class="flex w-full h-full absolute inset-0 outline-none z-50"
    tabindex="0"
    @keydown.esc="close">
    <div class="bg-black opacity-25 w-full h-full fixed z-50">
    </div>
    <div
      class="modal bg-gray-100 my-0 sm:my-16 absolute px-10 pb-10 flex flex-col
      rounded z-50 max-w-100vw w-full  md:w-5/6 mt-20 pt-6">
      <button
        class="ml-auto text-sans font-bold text-gray-900"
        type="text"
        @click="close"
      >
        Close
      </button>
      <template>
        <EditProfileForm
        :currentUser="currentUser"
        :profile="profile"
        :onCancel="cancelEditMode"
        :onSave="saveProfileChanges"
        :editError="editError">
        </EditProfileForm>
      </template>
    </div>
  </section>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import EditProfileForm from '@/components/EditProfileForm.vue';

export default {
  name: 'SuperUserProfileEditModal',
  components: {
    EditProfileForm,
  },
  data() {
    return {
      profile: {},
      editError: '',
    };
  },
  props: {
    userId: {
      type: Number,
    },
    /**
     * returns currently logged in user from profiles list
     */
    currentUser: {
      default: () => {},
    },
    /**
     * Hide modal to exit
     */
    hideModal: {
      type: Function,
    },
  },
  beforeMount() {
    const currentProfile = this.users.results.find((x) => {
      return x.id === this.userId;
    });

    // the original api call for this makes this look different..
    this.profile = {
      firstName: currentProfile.firstName,
      lastName: currentProfile.lastName,
      id: currentProfile.id,
      bio: currentProfile.profile.bio,
      email: currentProfile.email,
      employer: currentProfile.profile.employer,
      city: currentProfile.profile.city,
      country: currentProfile.profile.country,
      link: currentProfile.profile.link,
      major: currentProfile.profile.major,
      previousFirstName: currentProfile.profile.previousFirstName,
      previousLastName: currentProfile.profile.previousLastName,
      profilePicture: currentProfile.profile.profilePicture,
      state: currentProfile.profile.state,
      school: currentProfile.profile.school,
      showEmail: currentProfile.profile.showEmail,
      recieveAdminEmails: currentProfile.profile.receiveAdminEmails,
      hideWelcomeBanner: true,
      phoneNumber: currentProfile.profile.phoneNumber,
      user: {
        socialMedia: currentProfile.user.socialMedia,
      },
    };
  },
  mounted() {
    // used to allow closing with esc button
    if (this.$refs.modal) {
      this.$refs.modal.focus();
    }
  },
  computed: {
    ...mapGetters(['isLoading', 'users']),
    buttonIsDisabled() {
      return this.isLoading || !this.message;
    },
  },
  methods: {
    ...mapActions(['saveUser']),
    updateMessage(message) {
      this.message = message;
    },
    close() {
      this.hideModal();
    },
    cancelEditMode() {
      this.hideModal();
    },
    saveProfileChanges(profile) {
      const currentUser = this.users.results.find((x) => {
        return x.id === this.userId;
      });

      // take what we get back from the profile screen and make it into what this methos likes
      currentUser.email = profile.email;
      currentUser.firstName = profile.firstName;
      currentUser.lastName = profile.lastName;
      currentUser.profile.bio = profile.bio;
      currentUser.profile.city = profile.city;
      currentUser.profile.country = profile.country;
      currentUser.profile.employer = profile.employer;
      currentUser.profile.hideWelcomeBanner = profile.hideWelcomeBanner;
      currentUser.profile.link = profile.link;
      currentUser.profile.major = profile.major;
      currentUser.profile.previousFirstName = profile.previousFirstName;
      currentUser.profile.previousLastName = profile.previousLastName;
      currentUser.profile.profilePicture = profile.profilePicture;
      currentUser.profile.receiveAdminEmails = profile.receiveAdminEmails;
      currentUser.profile.school = profile.school;
      currentUser.profile.showEmail = profile.showEmail;
      currentUser.profile.state = profile.state;
      currentUser.profile.zip = profile.zip;
      currentUser.socialMedia = profile.socialMedia;
      currentUser.profile.phoneNumber = profile.phoneNumber;

      this.saveUser(currentUser).then(() => {
        this.hideModal();
      }).catch(() => {
        this.editError = 'An error has ocurred';
      });
    },
  },
};
</script>
<style scoped>
  .link {
    overflow-wrap: break-word;
  }
  .modal {
    left: 50%;
    transform: translateX(-50%);
  }
</style>
