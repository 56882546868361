<template>
  <div>
    <Toast v-if="showToast">{{ toastText }} updated!</Toast>

    <SuperAdminNavigation
      :currentPageView="currentPageView"
      :updateCurrentPageView="updateCurrentPageView"
    />
    <template v-if="currentPageView === 'users' || currentPageView === 'groupUsers'">
      <div class="mx-auto max-w-screen-xl flex flex-col-reverse xl:flex-row sm:mt-12">
        <div class="md:bg-gray-200 flex flex-col rounded max-w-full mx-auto w-full">
          <div class="flex items-center justify-between p-4">
            <h1 class="text-gray-900 font-serif text-2xl">
              Users
              <template v-if="currentPageView === 'groupUsers'">
                in {{ this.currentGroupName }}</template
              >
            </h1>
          </div>
          <div class="flex items-center sm:flex-row flex-col-reverse">
            <div class="sm:w-3/4 px-4 pb-2 w-full">
              <TextInput
                id="search"
                placeholder="Search members..."
                label="Search"
                :value="searchValue"
                @input="updateSearch"
                v-if="showSearch"
              >
                <template v-slot:icon>
                  <SearchIcon />
                </template>
              </TextInput>
            </div>
          </div>
          <h4 v-if="loading" class="loading p-4 text-lg text-gray-900 font-bold">Loading</h4>

          <table
            class="user-table w-full block p-5"
            v-if="!loading"
            cellspacing="0"
            cellpadding="0"
          >
            <tr class="flex flex-wrap md:flex-no-wrap md:wrapped">
              <th
                class="flex name-cell w-1/5 px-4 items-center cursor-pointer"
                @click="sortProfiles('last_name')"
              >
                Last Name, First Name
                <span
                  v-if="currentUserSort === 'last_name'"
                  class="w-4"
                  :class="[
                    {
                      'rotate-180-local': currentUserSortDirection === 'asc',
                    },
                  ]"
                >
                  <Chevron />
                </span>
              </th>
              <th
                class="w-1/4 bg-gray-200 md:bg-transparent md:flex items-center cursor-pointer"
                @click="sortProfiles('email')"
              >
                Email
                <span
                  v-if="currentUserSort === 'email'"
                  class="w-4"
                  :class="[
                    {
                      'rotate-180-local': currentUserSortDirection === 'asc',
                    },
                  ]"
                >
                  <Chevron />
                </span>
              </th>
              <th
                class="bg-gray-200 md:bg-transparent w-1/12 md:flex items-center cursor-pointer"
                @click="sortProfiles('date_joined')"
              >
                Joined Date
                <span
                  v-if="currentUserSort === 'date_joined'"
                  class="w-4"
                  :class="[
                    {
                      'rotate-180-local': currentUserSortDirection === 'asc',
                    },
                  ]"
                >
                  <Chevron />
                </span>
              </th>
              <th
                class="bg-gray-200 md:bg-transparent w-1/12 md:flex items-center cursor-pointer"
                @click="sortProfiles('last_login')"
              >
                Last Login
                <span
                  v-if="currentUserSort === 'last_login'"
                  class="w-4"
                  :class="[
                    {
                      'rotate-180-local': currentUserSortDirection === 'asc',
                    },
                  ]"
                >
                  <Chevron />
                </span>
              </th>
              <th
                class="md:pr-4 w-1/12 md:flex items-center cursor-pointer"
                @click="sortProfiles('is_active')"
              >
                Active
                <span
                  v-if="currentUserSort === 'is_active'"
                  class="w-4"
                  :class="[
                    {
                      'rotate-180-local': currentUserSortDirection === 'asc',
                    },
                  ]"
                >
                  <Chevron />
                </span>
              </th>
              <th
                v-if="currentPageView === 'groupUsers'"
                class="md:pr-4 w-2/12 md:flex items-center"
              >
                Group Admin
              </th>
              <th
                v-if="currentPageView !== 'groupUsers'"
                class="md:pr-4 w-2/12 md:flex items-center"
              >
                Groups
              </th>
              <th class="md:pr-4 w-1/8 md:flex items-center">Actions</th>
            </tr>
            <template v-for="user in filteredProfiles">
              <SuperAdminUserTableRow
                :user="user"
                :key="user.id"
                :groupSlug="currentGroup"
                v-on:user-selected="selectUser"
                @refetch="filterProfiles"
              />
            </template>
          </table>
        </div>
        <div class="flex-1 hidden"></div>
      </div>
    </template>
    <template v-if="currentPageView === 'groups'">
      <div class="mx-auto max-w-screen-xl flex flex-col-reverse xl:flex-row sm:mt-12">
        <div class="md:bg-gray-200 flex flex-col rounded max-w-full mx-auto w-full">
          <div class="flex items-center justify-between p-4">
            <h1 class="text-gray-900 font-serif text-2xl">Groups</h1>
          </div>
          <div class="flex flex-col px-4">
            <div class="sm:w-3/4 pb-2 w-full">
              <TextInput
                id="search"
                placeholder="Search groups..."
                label="Search"
                :value="searchValue"
                @input="updateSearch"
              >
                <template v-slot:icon>
                  <SearchIcon />
                </template>
              </TextInput>
            </div>
            <Checkbox
              v-model="hideDeactivatedGroups"
              :label-styles="'font-semibold -ml-1'"
              :large-only="true"
              compact
              label="Hide all deactivated groups"
            />
          </div>
          <h4 v-if="loading" class="loading p-4 text-lg text-gray-900 font-bold">Loading</h4>

          <table
            class="user-table w-full block p-5"
            v-if="!loading"
            cellspacing="0"
            cellpadding="0"
          >
            <tr class="flex flex-wrap md:flex-no-wrap md:wrapped">
              <th
                class="flex name-cell w-[25%] items-center px-4 cursor-pointer"
                @click="sortGroups('name')"
              >
                Group Name
                <span
                  v-if="currentGroupSort === 'name'"
                  class="w-4"
                  :class="[
                    {
                      'rotate-180-local': currentGroupSortDirection === 'asc',
                    },
                  ]"
                >
                  <Chevron />
                </span>
              </th>
              <th
                class="w-1/3 md:w-[15%] md:flex items-center cursor-pointer"
                @click="sortGroups('created_at')"
              >
                Date Created
                <span
                  v-if="currentGroupSort === 'created_at'"
                  class="w-4"
                  :class="[
                    {
                      'rotate-180-local': currentGroupSortDirection === 'asc',
                    },
                  ]"
                >
                  <Chevron />
                </span>
              </th>
              <th
                class="w-1/3 md:w-[15%] md:flex items-center cursor-pointer"
                @click="sortGroups('number_of_members')"
              >
                Member Count
                <span
                  v-if="currentGroupSort === 'number_of_members'"
                  class="w-4"
                  :class="[
                    {
                      'rotate-180-local': currentGroupSortDirection === 'asc',
                    },
                  ]"
                >
                  <Chevron />
                </span>
              </th>
              <th
                class="flex name-cell w-[10%] items-center px-4 cursor-pointer"
                @click="sortGroups('is_deactivated')"
              >
                Active
                <span
                  v-if="currentGroupSort === 'is_deactivated'"
                  class="w-4"
                  :class="[
                    {
                      'rotate-180-local': currentGroupSortDirection === 'asc',
                    },
                  ]"
                >
                  <Chevron />
                </span>
              </th>
              <th class="w-[15%] md:flex items-center">Admins</th>
              <th class="w-[20%] md:flex items-center">Actions</th>
            </tr>
            <template v-for="group in filteredGroups">
              <SuperAdminGroupTableRow
                :group="group"
                :key="group.slug"
                v-on:members-selected="selectGroup"
                @refetch="filterGroups"
              />
            </template>
          </table>
        </div>
        <div class="flex-1 hidden">
          <!-- TODO: under construction mode -->
        </div>
      </div>
    </template>
    <Paginate
      v-if="showPaginate"
      class="flex list-reset border border-grey-light rounded w-auto font-sans mx-auto max-w-screen-xl flex flex-col-reverse xl:flex-row sm:my-12"
      v-model="currentPage"
      :pageCount="pageCount"
      :clickHandler="paginationClick"
      :container-class="'pagination'"
      :page-class="'page-item'"
      :page-link-class="'block text-blue border-r border-grey-light px-3 py-2'"
      :prev-link-class="'block  text-blue border-r border-grey-light px-3 py-2'"
      :next-class="'next-item'"
      :next-link-class="'block  text-blue border-r border-grey-light px-3 py-2'"
      :break-view-class="'break-view'"
      :break-view-link-class="'break-view-link'"
      :first-last-button="true"
    ></Paginate>
    <template v-if="showProfileModal">
      <SuperUserProfileEditModal :hideModal="hideProfileModal" :userId="currentSelectedUserId" />
    </template>
  </div>
</template>

<script>
import SuperAdminNavigation from '@/components/SuperAdminNavigation.vue';
import SuperAdminUserTableRow from '@/components/SuperAdminUserTableRow.vue';
import SuperAdminGroupTableRow from '@/components/SuperAdminGroupTableRow.vue';
import TextInput from '@/components/TextInput.vue';
import SearchIcon from '@/assets/search.svg';
import Chevron from '@/assets/chevron.svg';
import Toast from '@/components/Toast.vue';
import Paginate from 'vuejs-paginate';
// import Button from '@/components/Button.vue';
import { mapActions, mapGetters } from 'vuex';
import SuperUserProfileEditModal from '@/components/SuperUserProfileEditModal.vue';
import Checkbox from '@/components/Checkbox.vue';

export default {
  name: 'SuperAdmin',
  components: {
    Checkbox,
    SuperAdminNavigation,
    TextInput,
    SearchIcon,
    SuperAdminUserTableRow,
    SuperAdminGroupTableRow,
    Chevron,
    Toast,
    Paginate,
    SuperUserProfileEditModal,
    // Button,
  },
  computed: {
    ...mapGetters(['user', 'users', 'groups']),
    userSortQuery() {
      return this.currentUserSortDirection === 'asc'
        ? this.currentUserSort
        : `-${this.currentUserSort}`;
    },
    groupSortQuery() {
      return this.currentGroupSortDirection === 'asc'
        ? this.currentGroupSort
        : `-${this.currentGroupSort}`;
    },
    toastText() {
      return this.currentPageView === 'groups' ? 'Group' : 'User';
    },
    pageCount() {
      switch (this.currentPageView) {
        case 'users':
        case 'groupUsers':
          return Math.ceil(this.users.count / this.resultsPerPage);
        case 'groups':
          return Math.ceil(this.groups.count / this.resultsPerPage);
        default:
          return 1;
      }
    },

    showSearch() {
      return true;
    },
    showPaginate() {
      return true;
    },
    currentProfile() {
      if (this.currentSelectedUserId !== 0) {
        return this.filteredProfiles.find((x) => {
          return x.Id === this.currentSelectedUserId;
        });
      }
      return {};
    },
  },
  watch: {
    profiles() {
      if (this.profiles !== this.groupProfiles) {
        this.groupProfiles = this.profiles;
        this.filterProfiles();
      }
    },
    currentPageView() {
      this.currentPage = 1;
      if (this.currentPageView === 'groups') {
        this.filterGroups();
      } else if (this.currentPageView === 'users') {
        this.filterProfiles();
      } else {
        this.filterGroupUsers();
      }
    },
    hideDeactivatedGroups() {
      this.filterGroups();
    },
  },
  data() {
    return {
      loading: false,
      currentPageView: 'groups',
      selectedDropdownOption: {
        label: 'All',
        value: 'all',
      },
      searchValue: '',
      currentUserSort: 'date_joined',
      currentUserSortDirection: 'desc',
      currentGroupSort: 'created_at',
      currentGroupSortDirection: 'desc',
      filteredProfiles: [],
      filteredGroups: [],
      showToast: false,
      currentPage: 1,
      resultsPerPage: 50,
      currentGroup: '',
      currentGroupName: '',
      currentSelectedUserId: 0,
      showProfileModal: false,
      hideDeactivatedGroups: true,
    };
  },
  created() {
    this.loading = false;
    if (this.user && this.user.user) {
      if (!this.user.user.isStaff || !this.user.user.isSuperuser) {
        this.$router.push({ name: 'home' });
      }
    } else {
      this.fetchCurrentUser().then(() => {
        if (!this.user.user.isStaff || !this.user.user.isSuperuser) {
          this.$router.push({ name: 'home' });
        }
      });
    }

    this.filterGroups();
  },
  methods: {
    ...mapActions([
      'fetchCurrentUsers',
      'fetchCurrentUser',
      'fetchCurrentGroups',
      'fetchGroupUsers',
    ]),
    updateCurrentPageView(view) {
      this.currentPageView = view;
    },
    getButtonActiveState(type) {
      return this.selectedView === type ? 'active' : 'inactive';
    },
    updateSearch(value) {
      this.searchValue = value;
      clearTimeout(this.searchDebounce);
      this.searchDebounce = setTimeout(() => {
        this.currentPage = 1;
        if (this.currentPageView === 'users') {
          this.filterProfiles();
        } else if (this.currentPageView === 'groups') {
          this.filterGroups();
        } else {
          this.filterGroupUsers();
        }
      }, 400);
    },
    filterProfiles() {
      const params = {
        searchTerm: this.searchValue,
        ordering: this.userSortQuery,
        page: this.currentPage,
        pageSize: this.resultsPerPage,
      };
      this.fetchCurrentUsers(params).then(() => {
        // this is a pain...
        this.filteredProfiles = this.users.results;
        this.filteredProfiles.forEach((element) => {
          element.user = {};
          element.user.socialMedia = [];
          element.socialMedia.forEach((element1) => {
            element.user.socialMedia.push({ mediaType: element1.mediaType.id, url: element1.url });
          });
        });
      });
    },
    sortProfiles(s) {
      if (s === this.currentUserSort) {
        this.currentUserSortDirection = this.currentUserSortDirection === 'asc' ? 'desc' : 'asc';
      }
      this.currentUserSort = s;
      if (this.currentPageView === 'users') {
        this.filterProfiles();
      } else {
        this.filterGroupUsers();
      }
    },
    filterGroups() {
      const params = {
        searchTerm: this.searchValue,
        ordering: this.groupSortQuery,
        page: this.currentPage,
        pageSize: this.resultsPerPage,
      };
      if (this.hideDeactivatedGroups) {
        params.isDeactivated = false;
      }
      this.fetchCurrentGroups(params).then(() => {
        this.filteredGroups = this.groups.results;
      });
    },
    selectGroup({ slug, name }) {
      this.currentGroup = slug;
      this.currentGroupName = name;
      this.currentPageView = 'groupUsers';
    },

    filterGroupUsers() {
      const params = {
        slug: this.currentGroup,
        searchTerm: this.searchValue,
        ordering: this.userSortQuery,
        page: this.currentPage,
        pageSize: this.resultsPerPage,
      };
      this.fetchGroupUsers(params).then(() => {
        this.filteredProfiles = this.users.results;
      });
    },
    sortGroups(s) {
      // if s === current sort, reverse
      if (s === this.currentGroupSort) {
        this.currentGroupSortDirection = this.currentGroupSortDirection === 'asc' ? 'desc' : 'asc';
      }
      this.currentGroupSort = s;
      this.filterGroups();
    },
    selectView(view) {
      this.selectedDropdownOption = view;
      if (view.value === 'all') {
        this.displayAllUsers();
      } else if (view.value === 'active') {
        this.displayActiveUsers();
      } else if (view.value === 'pending') {
        this.displayPendingUsers();
      }
    },
    paginationClick(pageNumber) {
      this.currentPage = pageNumber;
      switch (this.currentPageView) {
        case 'groups':
          this.filterGroups();
          break;
        case 'users':
          this.filterProfiles();
          break;
        case 'groupUsers':
          this.filterGroupUsers();
          break;
        default:
          break;
      }
    },
    selectUser({ id }) {
      this.currentSelectedUserId = id;
      this.showProfileModal = true;
    },
    hideProfileModal() {
      this.showProfileModal = false;
    },
    editError() {},
  },
};
</script>

<style lang="scss" scoped>
.rotate-180-local {
  transform: rotate(180deg);
}
.page-item .active {
  background-color: #3490dc;
}
</style>

<style lang="scss">
.page-item.active {
  background-color: #3490dc;
  color: white;
}
</style>
