<template>
  <div
    :class="{ 'px-6 sm:px-12 lg:px-10 lg:mx-auto': onUserProfile }"
    class="pb-8 pt-7 sm:py-12 lg:py-16 flex flex-col xl:max-w-screen-xl"
  >
    <Breadcrumbs v-if="onUserProfile" class="mb-3" :crumbs="breadcrumbs"></Breadcrumbs>

    <div v-if="onUserProfile" class="w-full mb-7 sm:mb-8 lg:mb-11">
      <div class="flex flex-row justify-between">
        <h1>{{ this.onCreateGroupFlow ? 'Create your' : 'Edit your' }} profile</h1>
        <button
          type="button"
          class="open-modal-btn ml-2 h-12 animation-focus-default sm:mr-4 hover:ring-1 hover:ring-orange-400 border-orange-400"
          @click="showPortal"
        >
          <div class="flex text-orange-400 font-bold">Manage Subscriptions</div>
        </button>
      </div>
      <h5 class="text-sm lg:text-0_8xl mt-4 mb-4 w-full sm:w-1/2">
        Your profile is used for all of your groups, so updating it updates it for all groups your a
        member of.
      </h5>

      <p v-if="isAdmin" class="text-gray-900 font-sans text-sm italic">
        {{ getStatusText() }}
      </p>
    </div>

    <div class="flex space-between sm:flex-row-reverse flex-col">
      <div
        v-if="!onCreateGroupFlow"
        class="flex-1 flex-col justify-center mb-8 sm:ml-6 lg:ml-8 sm:mt-7"
      >
        <PhotoUploadInlineForm
          @submitted="handlePhotoUpload"
          :invalid="invalidFields.includes('profilePicture')"
          :existingImage="editedProfile.profilePicture"
        />
      </div>

      <div class="flex flex-col flex-3">
        <template v-if="isAdmin && showStatusDropdown">
          <div class="sm:w-1/2 pr-2">
            <Dropdown
              class="mb-6 mr-0 sm:mr-2"
              :fullWidth="true"
              placeholder="Select an option"
              label="Status"
              :options="dropdownOptions"
              :selectedOption="selectedDropdownOption"
              @selection="changeStatus"
            />
          </div>
        </template>
        <!-- TODO: remove when this gets changed on the backend -->
        <div v-if="isAdmin && showStatusDropdown" class="flex mb-8 rounded bg-gray-200">
          <div
            class="hidden sm:flex text-gray-500 font-sans uppercase text-xs pl-4 pr-2 items-center self-stretch tracking-widest"
          >
            Note:
          </div>
          <h3 class="text-gray-600 font-sans text-sm p-3 sm:p-2">
            Account statuses automatically get set to <em>Active</em> when a member completes or
            edits their profile unless they are
            <em>Suspended</em>
          </h3>
        </div>
        <div v-if="onCreateGroupFlow" class="flex-1 mb-6 flex">
          <PhotoUploadInlineForm
            @submitted="handlePhotoUpload"
            :invalid="invalidFields.includes('profilePicture')"
            :existingImage="editedProfile.profilePicture"
            :showUnderPhotoText="false"
          />
        </div>
        <div class="flex flex-col lg:flex-row mb-6">
          <TextInput
            id="firstName"
            label="First Name"
            class="mb-8 lg:mb-0 lg:mr-4"
            :class="{ hidden: this.onCreateGroupFlow }"
            :invalid="invalidFields.includes('firstName')"
            v-model="editedProfile.firstName"
          >
          </TextInput>
          <TextInput
            id="lastName"
            label="Last Name"
            class="lg:ml-4"
            :class="{ hidden: this.onCreateGroupFlow }"
            :invalid="invalidFields.includes('lastName')"
            v-model="editedProfile.lastName"
          >
          </TextInput>
        </div>

        <div class="relative pb-8 border-b border-gray-300 mb-8">
          <Checkbox
            class="share-prev-name-checkbox"
            id="share_names"
            v-model="sharesPrevName"
            @change="sharePrevNameClick()"
            :label-styles="'font-semibold -ml-1'"
            :large-only="true"
            compact
            label="Share previous name people might know you by"
          />

          <transition name="fade-up" mode="in-out">
            <div v-if="sharesPrevName" class="flex flex-col lg:flex-row mt-6">
              <TextInput
                id="prev_first_name"
                label="Previous First Name"
                v-model="editedProfile.previousFirstName"
                class="mb-8 lg:mb-0 lg:mr-4"
              />
              <TextInput
                id="prev_last_name"
                label="Previous Last Name"
                v-model="editedProfile.previousLastName"
                class="lg:ml-4"
              />
            </div>
          </transition>
        </div>

        <div class="mb-8">
          <MapsAutocompleteInput
            id="city"
            :search-types="'(cities)'"
            label="Where do you live?"
            placeholder="Current city"
            :value="getFormattedCity"
            name="city"
            :invalid="invalidFields.includes('city')"
            @updateGeo="handleUpdateGeo"
            @updateCity="handleUpdateCity"
            @updateState="handleUpdateState"
            @updateCounty="handleUpdateCountry"
          ></MapsAutocompleteInput>
        </div>

        <div class="mb-8">
          <TextInput
            id="school"
            label="Which school(s) did / do you attend?"
            placeholder="(e.g. Georgetown University)"
            :maxLength="70"
            v-model="editedProfile.school"
          >
          </TextInput>
        </div>

        <div class="mb-8">
          <TextInput
            id="major"
            label="What did / do you study?"
            placeholder="Major (e.g. Psychology)"
            :maxLength="70"
            v-model="editedProfile.major"
          >
          </TextInput>
        </div>

        <div class="mb-8">
          <TextInput
            id="work"
            label="Where do you work?"
            placeholder="Work (e.g. Intern at Lunacy Productions)"
            :maxLength="70"
            v-model="editedProfile.employer"
          >
          </TextInput>
        </div>

        <div
          v-for="(field, index) in editedProfile.customGroupValues"
          class="mb-8"
          :key="`custom-field-${field.fieldSlug}`"
        >
          <TextInput
            :id="field.fieldSlug"
            :label="field.fieldIcon + ' ' + field.fieldQuestion"
            :placeholder="field.fieldLabel"
            :maxLength="70"
            v-model="editedProfile.customGroupValues[index].value"
          >
          </TextInput>
        </div>

        <div class="pb-8 border-b border-gray-300 mb-8">
          <div class="text-sm font-semibold text-gray-800 mb-2">
            Write a brief bio about yourself.
            <div class="hidden sm:block text-gray-800">
              Talk about what you have been up to lately
            </div>
          </div>
          <VueEditor
            :customModules="customModulesForEditor"
            :editorOptions="editorSettings"
            ref="bio"
            @image-added="handleImageAdded"
            class="bg-white shadow border border-gray-300 text-lg text-gray-900 font-sans rounded w-full lg:w-full"
            v-model="editedProfile.bio"
            placeholder="Write a bio"
            :editorToolbar="isMobile ? customToolbarMobile : customToolbar"
          />
        </div>

        <div class="mb-8">
          <p class="text-gray-800 text-sm font-semibold mb-2">This person is deceased</p>
          <p class="flex gap-2 items-center">
            <span
              class="text-sm"
              :class="
                !editedProfile.isDeceased
                  ? 'text-gray-900 font-semibold'
                  : 'text-gray-800 font-normal opacity-80'
              "
            >
              No
            </span>
            <toggle-button
              v-model="editedProfile.isDeceased"
              :width="46"
              :height="26"
              :color="toggleColor"
            />
            <span
              class="text-sm"
              :class="
                editedProfile.isDeceased
                  ? 'text-gray-900 font-semibold'
                  : 'text-gray-800 font-normal opacity-80'
              "
            >
              Yes
            </span>
          </p>
        </div>

        <!-- If you need to add the date of death in the future, remove it "false" -->
        <div v-if="false" class="relative mb-8 w-1/2">
          <p class="text-sm font-semibold text-gray-800 mb-2">Date of death</p>
          <CalendarInput
            id="nextEventDate"
            label="Select date"
            focusedPlaceholder="MM.DD.YYYY"
            :readonly="true"
            :showLabel="true"
            v-model="editedProfile.dateOfDeath"
            :localStringFormat="timeLocalFormat"
            :date-restrict-function="disableFutureDates"
          />
        </div>

        <div
          v-if="!editedProfile.isDeceased"
          class="mb-9 sm:mb-11 border-b border-gray-300 sm:border-0 pb-4 sm:pb-0"
        >
          <h4 class="font-bold text-xl mb-6">Social media</h4>

          <div class="media-container flex flex-col sm:flex-row mb-6">
            <div class="flex items-center w-full sm:w-130px mb-3 sm:mb-0 text-sm font-semibold">
              Instagram
            </div>
            <TextInput
              :id="'social-media-instagram'"
              placeholder="@username or URL"
              v-model="instagram"
            />
          </div>

          <div class="media-container flex flex-col sm:flex-row mb-6">
            <div class="flex items-center w-full sm:w-130px mb-3 sm:mb-0 text-sm font-semibold">
              Facebook
            </div>
            <TextInput :id="'social-media-facebook'" placeholder="URL" v-model="facebook" />
          </div>

          <div class="media-container flex flex-col sm:flex-row mb-6">
            <div class="flex items-center w-full sm:w-130px mb-3 sm:mb-0 text-sm font-semibold">
              LinkedIn
            </div>
            <TextInput :id="'social-media-linkedin'" placeholder="URL" v-model="linkedin" />
          </div>

          <div class="media-container flex flex-col sm:flex-row mb-6">
            <div class="flex items-center w-full sm:w-130px mb-3 sm:mb-0 text-sm font-semibold">
              Twitter
            </div>
            <TextInput
              :id="'social-media-twitter'"
              placeholder="@username or URL"
              v-model="twitter"
            />
          </div>
        </div>

        <div v-if="!editedProfile.isDeceased" class="mb-8">
          <TextInput
            id="link"
            label="Add a link to your website"
            placeholder="Website"
            v-model="editedProfile.link"
            class="sm:mr-2"
          />
        </div>

        <div v-if="!editedProfile.isDeceased" class="mb-8">
          <MapsAutocompleteInput
            id="streetAddress"
            :class="{ hidden: this.onCreateGroupFlow }"
            label="Mailing Address (Street, City, State, Zip) (visible to Admins only)"
            placeholder="Street, City, State, Zip"
            type="mailing_address"
            types="address"
            :invalid="invalidFields.includes('streetAddress')"
            v-model="editedProfile.streetAddress"
            @updateFullAddress="handleUpdateFullAddress"
            class="sm:mr-2"
          />
        </div>

        <div v-if="!editedProfile.isDeceased" class="mb-8">
          <TextInput
            mask="(###)###-####"
            id="Phone"
            label="Phone"
            type="tel"
            placeholder="(555)555-5555"
            v-model="editedProfile.phoneNumber"
            class="sm:mr-2"
            error-message="Invalid Phone Number"
            :invalid="invalidFields.includes('phoneNumber')"
          />
        </div>

        <div class="mb-8">
          <TextInput
            id="email"
            :class="{ hidden: this.onCreateGroupFlow || this.profile.isManagedByAdmin }"
            label="Email Address (Required)"
            placeholder="Email"
            type="email"
            :invalid="invalidFields.includes('email')"
            v-model="editedProfile.email"
            class="sm:mr-2"
          />
        </div>

        <div v-if="!editedProfile.isDeceased" class="pb-8 border-b border-gray-300 mb-8 w-full">
          <div class="">
            <Checkbox
              id="show_email"
              :large-only="true"
              compact
              :label-styles="'font-semibold -ml-1'"
              v-model="editedProfile.showEmail"
              label="Display email on profile"
            />
            <Checkbox
              id="receive_emails"
              class="mt-4"
              :large-only="true"
              compact
              :label-styles="'font-semibold -ml-1'"
              v-model="editedProfile.receiveAdminEmails"
              label="Subscribe to group email updates"
            />
          </div>
        </div>

        <div class="flex">
          <Button
            v-if="!onCreateGroupFlow"
            class="save-btn w-1/2 sm:w-auto mr-4 h-56px"
            type="primary"
            :color-custom="!onUserProfile"
            :primary-full-size="false"
            :disabled="buttonIsDisabled"
            :loading="buttonIsDisabled"
            @onClick="updateProfile()"
          >
            Save
          </Button>
          <Button
            v-if="!onCreateGroupFlow"
            class="cancel-btn w-1/2 sm:w-auto"
            type="text"
            :disabled="buttonIsDisabled"
            @onClick="onCancel()"
          >
            Cancel
          </Button>
        </div>

        <div
          v-if="!onUserProfile && !onSuperAdmin && !onCreateGroupFlow"
          class="flex flex-col lg:flex-row justify-between bg-white border border-gray-300 shadow-drop rounded mt-12 sm:mt-16 px-9 py-5 lg:p-8"
          :class="{ 'items-center': !(isCurrentProfile && isSoleGroupAdmin) }"
        >
          <div class="flex" :class="{ 'items-center': !(isCurrentProfile && isSoleGroupAdmin) }">
            <CTAIcon class="hidden lg:block w-90px h-90px mr-6"></CTAIcon>

            <div class="block" :style="{ 'padding-top': isCurrentProfile && isSoleGroupAdmin ? '22px' : 0 }">
              <h3 class="mb-4 sm:mb-2 text-center lg:text-left">
                <template v-if="isCurrentProfile">Leave Group?</template>
                <template v-else>Remove from Group?</template>
              </h3>

              <div class="text-sm max-w-355px text-center lg:text-left">
                Removing this profile from
                <span class="font-bold">{{ this.group.name }}</span> will not affect its membership
                to any other groups.
              </div>
            </div>
          </div>
          <div
            :class="{'flex flex-col items-center w-5/12 gap-2': isCurrentProfile && isSoleGroupAdmin }"
          >
            <Button class="lg:h-56px mt-4 lg:mt-0" :disabled="isCurrentProfile && isSoleGroupAdmin" type="text" @onClick="openLeaveGroupModal">
              <template v-if="isCurrentProfile">
                <span class="text-base lg:px-5 flex">Leave</span>
              </template>
              <template v-else>
                <span class="text-base lg:px-5 flex">Remove</span>
              </template>
            </Button>
            <span v-if="isCurrentProfile && isSoleGroupAdmin" class="text-sm">
              You are the only Admin of this group.
              You will need to make at least one other Member an Admin before you can leave.
              To do this, open the Member’s profile and tap the “Make Admin” button.
            </span>
          </div>
        </div>

        <!--        todo: bind errors to inputs -->
        <!--        <template v-if="editError || error">-->
        <!--          <ErrorBox :message="editError || error"/>-->
        <!--        </template>-->

        <!--        <template v-if="invalidFields.length">-->
        <!--          <ErrorBox :message="getErrorMessage(invalidFields)"/>-->
        <!--        </template>-->
      </div>
    </div>

    <!--                    -->
    <!-- DIALOG LEAVE GROUP -->
    <!--                    -->

    <transition name="component-fade" mode="out-in">
      <DialogDefault
        v-if="leaveGroupModalOpen"
        :icon="'CTA'"
        :header="getLeaveGroupDialogName()"
        :text="getLeaveGroupDialogText()"
        :applyBtnText="isCurrentProfile ? 'Leave' : 'Remove'"
        @cancel="cancelLeaveGroupDialog()"
        @apply="removeFromGroup()"
      />
    </transition>
  </div>
</template>

<script>
import { VueEditor } from 'vue2-editor';
import ImageResize from 'quill-image-resize-module';
import { mapGetters, mapActions } from 'vuex';
import Button from '@/components/Button.vue';
import Dropdown from '@/components/Dropdown.vue';
import TextInput from '@/components/TextInput.vue';
import Checkbox from '@/components/Checkbox.vue';
import CalendarInput from '@/components/CalendarInput.vue';
import PhotoUploadInlineForm from '@/components/PhotoUploadInlineForm.vue';
import DialogDefault from '@/components/DialogDefault.vue';
import MapsAutocompleteInput from '@/components/MapsAutocompleteInput.vue';
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import CTAIcon from '@/assets/icon_CTA.svg';
import { DEFAULT_INTERACTIVE_COLOR } from '@/components/constants/groupColors';
import { constants } from '../config';

export default {
  name: 'EditProfileForm',
  components: {
    CalendarInput,
    Breadcrumbs,
    VueEditor,
    DialogDefault,
    Button,
    Dropdown,
    TextInput,
    Checkbox,
    PhotoUploadInlineForm,
    MapsAutocompleteInput,
    CTAIcon,
  },
  props: {
    /**
     * Profile to display
     */
    profile: {
      default: () => {},
    },
    /**
     * Save button handler
     */
    onSave: {
      type: Function,
    },
    /**
     * Cancel button handler
     */
    onCancel: {
      type: Function,
    },
    /**
     * Error if saving changes fails
     */
    editError: {
      type: String,
    },
    /**
     * returns currently logged in member from profiles list
     */
    currentUser: {
      default: () => {},
    },
    isSoleGroupAdmin: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    this.editedProfile = JSON.parse(JSON.stringify(this.profile));
    this.sharesPrevName = !!this.profile.previousFirstName || !!this.profile.previousLastName;
    // eslint-disable-next-line prefer-destructuring
    this.selectedDropdownOption = this.dropdownOptions.filter(
      (option) => option && option.value === this.profile.status,
    )[0];

    this.editedProfile.socialMedia = JSON.parse(JSON.stringify(this.profile.user.socialMedia));
    const instagram = this.editedProfile.socialMedia.find((element) => element.mediaType === 1);
    if (!instagram) {
      this.addSocialMediaOption(1, '');
    }
    const facebook = this.editedProfile.socialMedia.find((element) => element.mediaType === 2);
    if (!facebook) {
      this.addSocialMediaOption(2, '');
    }
    const linkedin = this.editedProfile.socialMedia.find((element) => element.mediaType === 3);
    if (!linkedin) {
      this.addSocialMediaOption(3, '');
    }
    const twitter = this.editedProfile.socialMedia.find((element) => element.mediaType === 4);
    if (!twitter) {
      this.addSocialMediaOption(4, '');
    }
  },

  mounted() {
    // eslint-disable-next-line no-undef
    this.cbInstance = Chargebee.init({
      site: constants.CHARGEBEE_SOURCE,
      isItemsModel: true,
    });
    this.portal = this.cbInstance.createChargebeePortal();
  },
  computed: {
    ...mapGetters(['group', 'user', 'isLoading']),
    toggleColor() {
      return {
        checked: `rgba(${this.getButtonDefaultColor()})`,
        unchecked: '#AAACAF',
        disabled: '#CCCCCC',
      };
    },
    timeLocalFormat() {
      return 'MM.dd.yyyy';
    },
    isMobile() {
      return document.body.clientWidth <= 576;
    },
    isCurrentUser() {
      return this.editedProfile.slug === this.user.slug;
    },
    onUserProfile() {
      return this.$route.name === 'user-profile';
    },
    onSuperAdmin() {
      return this.$route.name === 'super-admin';
    },
    onCreateGroupFlow() {
      return this.$route.name === 'create-group' || this.$route.name === 'signup';
    },
    buttonIsDisabled() {
      return this.isLoading;
    },
    isAdmin() {
      return (
        (this.currentUser && this.currentUser.isGroupAdmin) ||
        (this.user && this.user.user && this.user.user.isSuperuser)
      );
    },
    isCurrentProfile() {
      return this.user && this.user.slug === this.editedProfile.slug;
    },
    getFormattedCity() {
      if (!this.editedProfile.city) return '';
      return `${this.editedProfile.city}, ${this.editedProfile.state}, ${this.editedProfile.country}`;
    },
    showStatusDropdown() {
      // the status is group specific so it should only show when the user is viewing a group
      return this.$route.name !== 'user-profile' && this.$route.name !== 'super-admin';
    },
    instagram: {
      get() {
        // eslint-disable-next-line no-unused-expressions
        this.computedUpdate;
        const entry = this.editedProfile.socialMedia.find((element) => element.mediaType === 1);
        if (entry) {
          return entry.url;
        }
        return '';
      },
      set(val) {
        const entry = this.editedProfile.socialMedia.find((element) => element.mediaType === 1);
        entry.url = val;
        this.computedUpdate += 1;
      },
    },
    facebook: {
      get() {
        // eslint-disable-next-line no-unused-expressions
        this.computedUpdate;
        const entry = this.editedProfile.socialMedia.find((element) => element.mediaType === 2);
        if (entry) {
          return entry.url;
        }
        return '';
      },
      set(val) {
        const entry = this.editedProfile.socialMedia.find((element) => element.mediaType === 2);
        entry.url = val;
        this.computedUpdate += 1;
      },
    },
    linkedin: {
      get() {
        // eslint-disable-next-line no-unused-expressions
        this.computedUpdate;
        const entry = this.editedProfile.socialMedia.find((element) => element.mediaType === 3);
        if (entry) {
          return entry.url;
        }
        return '';
      },
      set(val) {
        const entry = this.editedProfile.socialMedia.find((element) => element.mediaType === 3);
        entry.url = val;
        this.computedUpdate += 1;
      },
    },
    twitter: {
      get() {
        // eslint-disable-next-line no-unused-expressions
        this.computedUpdate;
        const entry = this.editedProfile.socialMedia.find((element) => element.mediaType === 4);
        if (entry) {
          return entry.url;
        }
        return '';
      },
      set(val) {
        const entry = this.editedProfile.socialMedia.find((element) => element.mediaType === 4);
        entry.url = val;
        this.computedUpdate += 1;
      },
    },
  },
  data() {
    return {
      breadcrumbs: [
        {
          label: 'Profile',
          link: 'profile',
        },
      ],
      leaveGroupModalOpen: false,
      defaultButtonColor: DEFAULT_INTERACTIVE_COLOR,
      editedProfile: {},
      error: null,
      dropdownOptions: [
        {
          label: 'Active',
          value: 'active',
          iconColorSmall: 'bg-green-500',
        },
        {
          label: 'Pending',
          value: 'pending',
          iconColorSmall: 'bg-yellow-500',
        },
        {
          label: 'Suspended',
          value: 'suspended',
          iconColorSmall: 'bg-red-600',
        },
      ],
      socialMediaOptions: [
        {
          label: 'Instagram',
          value: 1,
        },
        {
          label: 'Facebook',
          value: 2,
        },
        {
          label: 'LinkedIn',
          value: 3,
        },
        {
          label: 'Twitter',
          value: 4,
        },
      ],
      sharesPrevName: false,
      selectedDropdownOption: {
        label: 'Active',
        value: 'active',
        iconColorSmall: 'bg-green-500',
      },
      invalidFields: [],
      requiredFields: ['firstName', 'lastName', 'email'],
      customToolbar: [
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ['bold', 'italic', 'underline'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        ['clean'],
        ['link'],
        ['image'],
      ],
      customToolbarMobile: [
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ['bold', 'italic', 'underline'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        ['clean'],
      ],
      customModulesForEditor: [
        {
          alias: 'imageResize',
          module: ImageResize,
        },
      ],
      editorSettings: {
        modules: {
          imageResize: {
            modules: ['Resize'],
          },
        },
      },
      computedUpdate: 1,
      socialMediaList: [],
      portal: null,
      cbInstance: null,
    };
  },
  methods: {
    ...mapActions(['removeUserFromGroup', 'uploadGroupFile']),
    disableFutureDates(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      return date > today;
    },
    getButtonDefaultColor() {
      return this.defaultButtonColor;
    },
    handleImageAdded(file, Editor, cursorLocation, resetUploader) {
      this.error = null;
      const formData = new FormData();
      formData.append('file', file);
      this.uploadGroupFile({
        groupSlug: this.$route.params.group,
        file: formData,
      })
        .then((result) => {
          const { url } = result; // Get url from response
          Editor.insertEmbed(cursorLocation, 'image', url);
          resetUploader();
        })
        .catch(() => {
          this.error = 'Error: Your image could not be inserted. Please try again.';
        });
    },
    openLeaveGroupModal() {
      this.leaveGroupModalOpen = true;
    },
    cancelLeaveGroupDialog() {
      this.leaveGroupModalOpen = false;
    },
    getLeaveGroupDialogName() {
      return this.isCurrentProfile
        ? 'Leave the group'
        : `Remove ${this.editedProfile.firstName} from the group`;
    },
    getLeaveGroupDialogText() {
      return this.isCurrentProfile
        ? 'Are you sure you want to leave the group?'
        : `Are you sure you want to remove ${this.editedProfile.firstName} from ${this.group.name}?`;
    },
    removeFromGroup() {
      this.removeUserFromGroup({
        profileSlug: this.editedProfile.slug,
        groupSlug: this.group.slug,
      }).then((res) => this.handleLeaveGroupResponse(res));
    },
    handleLeaveGroupResponse(res) {
      if (res.status === 204) {
        this.handleRedirect();
      } else {
        this.leaveGroupModalOpen = false;
        this.error = `This profile could not be removed from ${this.group.name}. Please try again.`;
      }
    },
    handleRedirect() {
      if (this.isCurrentUser) {
        // if it's the current user, go back to groups page
        this.$router.push({ name: 'groups' });
      } else {
        // if it's a group admin, go back to members
        this.$router.push({
          name: 'members',
          params: {
            group: this.group.slug,
          },
        });
      }
    },
    changeStatus(option) {
      // Google Analytics Event
      this.$gtag.event('changed_status', {
        event_category: 'profile',
        event_label: `${this.$route.params.group} / Changed user status to ${option.value}`,
      });

      this.selectedDropdownOption = option;
    },
    handlePhotoUpload(blob) {
      this.editedProfile.profilePicture = blob;
    },
    handleUpdateGeo(coordinates) {
      this.editedProfile.coordinates = coordinates;
    },
    handleUpdateCity(city) {
      this.editedProfile.city = city;
    },
    handleUpdateState(state) {
      this.editedProfile.state = state;
    },
    handleUpdateCountry(country) {
      this.editedProfile.country = country;
    },
    handleUpdateFullAddress(address) {
      this.editedProfile.streetAddress = address;
    },
    checkForErrors() {
      this.invalidFields = []; // reset errors before checking for them
      this.requiredFields.forEach((field) => {
        if (
          !this.editedProfile ||
          !this.editedProfile[field] ||
          (this.editedProfile[field] &&
            typeof this.editedProfile[field] === 'string' &&
            !this.editedProfile[field].trim())
        ) {
          this.invalidFields.push(field);
        }
      });
      if (this.editedProfile.phoneNumber) {
        const re = new RegExp('^\\d{10}$');
        if (!re.test(this.editedProfile.phoneNumber)) {
          this.invalidFields.push('phoneNumber');
        }
      }
      return !!this.invalidFields.length;
    },
    updateProfile() {
      const { previousFirstName, previousLastName } = this.editedProfile;
      this.error = null;
      // prevent form submission if we know values are invalid
      if (this.checkForErrors()) return;
      const user = {
        ...this.editedProfile,
        previousFirstName: !this.sharesPrevName || !previousFirstName ? '' : previousFirstName,
        previousLastName: !this.sharesPrevName || !previousLastName ? '' : previousLastName,
        link: this.editedProfile.link && this.editedProfile.link.replace(RegExp('https?://'), ''),
        socialMedia: this.editedProfile.socialMedia.filter((element) => element.url !== ''),
      };
      if (this.selectedDropdownOption) {
        user.status = this.selectedDropdownOption.value;
      }

      this.onSave(user, this.group.slug);
    },
    getEventDate() {
      return this.group.eventDate ? ` on ${this.group.eventDate}` : '';
    },
    getErrorMessage(fields) {
      const errors = fields
        .map((error) => {
          let formattedError = error;
          if (error === 'firstName') {
            formattedError = 'first name';
          } else if (error === 'lastName') {
            formattedError = 'last name';
          }
          return formattedError;
        })
        .join(', ');
      return `Required field${fields.length > 1 ? 's' : ''}: ${errors}`;
    },
    getStatusText() {
      if (this.selectedDropdownOption && this.selectedDropdownOption.value !== 'active') {
        return 'This profile is not visible to other members in the group';
      }
      return 'This profile is visible to other members in the group';
    },
    sharePrevNameClick() {
      // Google Analytics Event
      this.$gtag.event('previous_name_click', {
        event_category: 'profile',
        event_label: `${this.$route.params.group} / Toggled Share Previous Name`,
      });
    },
    addSocialMediaOption(type, url) {
      this.editedProfile.socialMedia.push({
        mediaType: type,
        url,
      });
      this.$forceUpdate();
    },
    getSelectedSocialMedia(mediaType) {
      return this.socialMediaOptions.filter((option) => option && option.value === mediaType)[0];
    },
    changeSocialMediaType(option, otherData) {
      const dropdown = this.editedProfile.socialMedia[otherData.index];
      dropdown.mediaType = option.value;
      this.$forceUpdate();
    },
    showPortal() {
      this.portal.open();
    },
  },
};
</script>

<style lang="scss">
.flex-3 {
  flex: 3;
}

#quill-container .ql-editor {
  @apply break-words;
  word-break: break-word;
}

#quill-container .ql-editor.ql-blank::before {
  font-style: normal;
}

.quillWrapper .ql-toolbar.ql-snow {
  padding-top: 12px !important;
  padding-bottom: 8px !important;
  border-bottom: 1px solid #eae5e1 !important;
}

.save-btn {
  .button-content {
    padding-left: 20px;
    padding-right: 20px;
    font-size: 18px !important;
    font-weight: 600 !important;
  }
}

.manage-btn {
  .button-content {
    padding-left: 20px;
    padding-right: 20px;
    font-size: 18px !important;
    font-weight: 600 !important;
  }
}

.cancel-btn {
  .button-content {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.remove-icon path {
  stroke: #c8cacd;
}

.open-modal-btn {
  @apply w-155px sm:w-auto flex justify-center items-center py-3 sm:py-2 px-4 sm:px-6 text-sm font-medium rounded border;
}

@media all and (max-width: 576px) {
  .save-btn {
    height: 40px;
    padding-top: 0;
    padding-bottom: 0;

    .button-content {
      font-size: 14px !important;
    }
  }
  .cancel-btn {
    padding-top: 0;
    padding-bottom: 0;
    height: 40px;
    font-size: 14px !important;
  }

  .share-prev-name-checkbox {
    .checkbox-input {
      width: auto !important;
    }

    .label-text {
      width: auto !important;
    }
  }

  .quillWrapper .ql-toolbar.ql-snow .ql-formats {
    margin-right: 10px;
  }
}
</style>
